@import "../css/variables.module";

.excalidraw {
  .ShareableLinkDialog {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    color: var(--text-primary-color);

    ::selection {
      background: var(--color-primary-light-darker);
    }

    h3 {
      font-family: "Assistant";
      font-weight: 700;
      font-size: 1.313rem;
      line-height: 130%;

      margin: 0;
    }

    &__popover {
      @keyframes RoomDialog__popover__scaleIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      box-sizing: border-box;
      z-index: 100;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      padding: 0.125rem 0.5rem;
      gap: 0.125rem;

      height: 1.125rem;

      border: none;
      border-radius: 0.6875rem;

      font-family: "Assistant";
      font-style: normal;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 110%;

      background: var(--color-success-lighter);
      color: var(--color-success);

      & > svg {
        width: 0.875rem;
        height: 0.875rem;
      }

      transform-origin: var(--radix-popover-content-transform-origin);
      animation: RoomDialog__popover__scaleIn 150ms ease-out;
    }

    &__linkRow {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 0.75rem;
    }

    &__description {
      border-top: 1px solid var(--color-gray-20);

      padding: 0.5rem 0.5rem 0;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 150%;

      & p {
        margin: 0;
      }

      & p + p {
        margin-top: 1em;
      }
    }
  }
}
